/** @jsx jsx */
import { jsx } from "theme-ui"
import { ENV } from "src/utils/constants"
import { graphql } from "gatsby"
import Link from "gatsby-link"

export default state => {
  const auctions = state.data.allContentfulMicrositeAuction.edges
  const cars = state.data.allContentfulMicrosite
  const env = state.data.site.siteMetadata.env
  function redirect() {
    typeof window !== "undefined" &&
      (window.location = "https://www.goodingco.com/")
  }

  switch (env) {
    case ENV.DEVELOPMENT:
    case ENV.STAGING:
      return (
        <div>
          <Link to={`/vehicle-microsite`}>vehicle</Link>

          {auctions.map(({ node }, index) => {
            return (
              <div key={index}>
                <Link to={`/${node.slug}`}>{node.title}</Link>
              </div>
            )
          })}
        </div>
      )

    case ENV.PRODUCTION:
    default:
      return <div>{redirect()}</div>
  }
}

export const pageQuery = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        env
      }
    }
    allContentfulMicrositeAuction(sort: { fields: [updatedAt], order: DESC }) {
      edges {
        node {
          createdAt(formatString: "MMMM Do, YYYY")
          title
          slug
        }
      }
    }

    allAuctionsJson {
      edges {
        node {
          name
          slug
        }
      }
    }
  }
`
